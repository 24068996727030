import React from 'react';
import { RiBook2Line } from "react-icons/ri";
import { GrArticle } from "react-icons/gr";
import { LuGraduationCap } from "react-icons/lu";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { PiVideo, PiGlobe } from "react-icons/pi";
import { TbProgressCheck } from "react-icons/tb";
import { TbProgress } from "react-icons/tb";
import { FaCircleCheck } from "react-icons/fa6"; 
import { LuHeadphones } from "react-icons/lu";

function LibraryFilter({
    filterType, 
    setFilterType, 
    filterFinished, 
    setFilterFinished,
}) {

    const toggleType = (type) => () => {
        setFilterType(filterType.includes(type) ? filterType.filter(t => t !== type) : [...filterType, type]);
    };

    const toggleFinished = (finished) => {
        return (event) => {
            event.stopPropagation();
            if (filterFinished === finished) {
                setFilterFinished(null);
            } else {
                setFilterFinished(finished);
            }
        };
    };

    return (
        <div className='resource-tab-filter'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='filter-labels'>
                    <div onClick={toggleType('bo')}
                        className={`type-filter-button ${filterType.includes('bo') ? 'active-filter' : ''}`}>
                        <RiBook2Line size={18} /> Books
                    </div>
                    <div onClick={toggleType('vi')}
                        className={`type-filter-button ${filterType.includes('vi') ? 'active-filter' : ''}`}>
                        <MdOutlineOndemandVideo size={18} /> Videos
                    </div>
                    <div onClick={toggleType('ar')}
                        className={`type-filter-button ${filterType.includes('ar') ? 'active-filter' : ''}`}>
                        <GrArticle size={18} /> Articles & Blogs
                    </div>
                    <div onClick={toggleType('po')}
                        className={`type-filter-button ${filterType.includes('po') ? 'active-filter' : ''}`}>
                        <LuHeadphones size={18} /> Podcasts
                    </div>
                    <div onClick={toggleType('oc')}
                        className={`type-filter-button ${filterType.includes('oc') ? 'active-filter' : ''}`}>
                        <LuGraduationCap size={18} /> Online Courses
                    </div>
                    <div onClick={toggleType('or')}
                        className={`type-filter-button ${filterType.includes('or') ? 'active-filter' : ''}`}>
                        <PiGlobe size={18} /> Other
                    </div>
                </div>
            </div>
            <div className={window.innerWidth > 900 ? 'rx-divider-horizontal' : ''}></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='filter-labels'>
                    <div onClick={toggleFinished('not_started')}
                        className={`type-filter-button ${filterFinished === 'not_started' ? 'active-filter' : ''}`}>
                        <TbProgress size={18} /> Not Started
                    </div>
                    <div onClick={toggleFinished('in_progress')}
                        className={`type-filter-button ${filterFinished === 'in_progress' ? 'active-filter' : ''}`}>
                        <TbProgressCheck size={18} /> In Progress
                    </div>
                    <div onClick={toggleFinished('finished')}
                        className={`type-filter-button ${filterFinished === 'finished' ? 'active-filter' : ''}`}>
                        <FaCircleCheck size={18} /> Finished
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LibraryFilter;

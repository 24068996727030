import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchUserSubject = async () => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.get('/api/subject/', { headers });

    if (!response.data || !response.data.serialized_data) {
      throw new Error('Invalid response data');
    }

    return {
      userSubject: response.data.serialized_data,
      userFans: response.data.fans_count
    };
  } catch (error) {
    console.error('Error fetching user subject:', error);
    throw error;
  }
};
export const convertResourceType = (type) => {
    switch (type) {
        case 'bo':
            return 'book';
        case 'ar':
            return 'article';
        case 'po':
            return 'podcast';
        case 'vi':
            return 'video';
        case 'oc':
            return 'online course';
        case 'or':
            return 'link';
        default:
            return 'link';
    }
};

export const cleanAuthorName = (authorString) => {
    if (!authorString) return ''; 
    return authorString.replace(/\([^)]*\)|\[[^\]]*\]/g, '').trim();
};
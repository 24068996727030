import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useToast } from '../ToastNotification';
import { useModal } from '../../Context/ModalContext';
// Icons
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdMoreVert } from "react-icons/md";
import { deleteResource } from "@linko/shared_utils";

const ResourceCardActions = ({ 
    link, 
    id, 
    onDelete, 
    isBook, 
    shareView, 
    handleAddToLibrary,
    currentResource,
    }) => {
        
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { addToast, setPersistentMessage } = useToast();
    const { requestConfirm, requestAlert } = useModal();

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDeleteResource = async () => {
        requestConfirm(
            'Are you sure you want to delete this resource?',
            async () => {
                await deleteResource(id);
                onDelete(id); 
                if (location.pathname === '/r/') {
                    safeNavigate('/my_linko');
                    setPersistentMessage('Resource deleted.', {appearance: 'success'});
                } else {
                    addToast('Resource deleted.', {appearance: 'success'});
                }
            },
            null,
            'Delete'
        );
    }
    
    if (currentResource) {
        return (
            <div className='card-action-laid'>
                {!shareView ? 
                    <div className='card-action-group delete-group' onClick={() => {handleDeleteResource(id); setIsDropdownOpen(false);}}>
                        <FaRegTrashAlt className='card-action-icon delete-icon' />
                        <p>Delete</p>
                    </div>
                : 
                    <div 
                        className='linko-button linko-button--primary' 
                        onClick={() => {handleAddToLibrary(); setIsDropdownOpen(false);}}
                        style={{height:'28px', fontSize:'14px'}}
                    >
                        <MdOutlineLibraryAdd className='card-action-icon' />
                        <p>Add to My Library</p>
                    </div>
                }
                {!isBook &&
                    <a className='card-action-group' href={link} onClick={() => {setIsDropdownOpen(false);}}>
                        <HiOutlineExternalLink className='card-action-icon' />
                        <p>Go to resource</p>
                    </a>                       
                }
            </div>
        );
    }

    return (
        <div>
            <div style={{height:'fit-content'}}>
                <MdMoreVert 
                    className="MoreTabWLink" 
                    onClick={toggleDropdown} 
                    role='moreicon'
                />
                {isDropdownOpen && (
                    <div ref={dropdownRef} className='card-action-dropdown'>
                        {!isBook &&
                            <a 
                                href={link}
                                role='action-group' 
                                className='card-action-group' 
                                onClick={() => {setIsDropdownOpen(false)}}
                            >
                                <HiOutlineExternalLink className='card-action-icon' />
                                <p>Go to resource</p>
                            </a>                       
                        }
                        {!shareView ? 
                            <div 
                                role='action-group' 
                                className='card-action-group delete-group' 
                                onClick={() => {
                                    handleDeleteResource(id); 
                                    setIsDropdownOpen(false);
                                }}
                            >
                                <FaRegTrashAlt className='card-action-icon delete-icon' />
                                <p>Delete</p>
                            </div>
                        : 
                            <div 
                                role='action-group' 
                                className='card-action-group' 
                                onClick={() => {
                                    handleAddToLibrary(); 
                                    setIsDropdownOpen(false);
                                }}
                            >
                                <MdOutlineLibraryAdd className='card-action-icon' />
                                <p>Add to My Library</p>
                            </div>
                        }
                    </div>
                )}
            </div>    
        </div>
    );
};

export default ResourceCardActions;

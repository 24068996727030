import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useNavigation } from '../../Context/NavigationContext';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';
import BackButton from '../../Components/BackButton';
import TheTabs from '../../Components/ContentTabs/TheTabs';
import SubjectPageHeader from './SubjectPageHeader';
import SubjectDescription from './SubjectDescription';
import SubjectRel from './SubjectRel';
import LeaderBoard from './LeaderBoard';
import RecentlyAdded from '../HomePage/RecentlyAdded';
import HalfFooter from '../../Components/NavAndFooter/HalfFooter';
import { IoClose } from "react-icons/io5";
import { fetchSubjectId, useWindowSize } from '@linko/shared_utils';
import WholeScreenModal from '../../Components/WholeScreenModal';
import Loader from '../../Components/Loader';

const SubjectPage = () => {

  const {subjectName} = useParams();
  const location = useLocation();
  const { pushPage } = useNavigation();
  const [subjectId, setSubjectId] = useState(null);
  const [description, setDescription] = useState('');
  const [broaderField, setBroaderField] = useState([]);
  const [siblingFields, setSiblingFields] = useState([]);
  const [childFields, setChildFields] = useState([]);
  const [publicField, setPublicField] = useState(null);
  const [noteCount, setNoteCount] = useState(null);
  const [resourceCount, setResourceCount] = useState(null);
  const [topUsers, setTopUsers] = useState([]);
  const [notes, setNotes] = useState([]);
  const [library, setLibrary] = useState([]);
  const [editTag, setEditTag] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width } = useWindowSize(); 

  const fetchSubjectInfo = useCallback(async () => {
    setLibrary([]); // Reset library state
    try {
      const subjectInfo = await fetchSubjectId(subjectName);
      setSubjectId(subjectInfo.subjectId);
      setDescription(subjectInfo.description);
      setPublicField(subjectInfo.publicField);
      setBroaderField(subjectInfo.broaderField);
      setSiblingFields(subjectInfo.siblingFields);
      setChildFields(subjectInfo.childFields);
      setNoteCount(subjectInfo.noteCount);
      setResourceCount(subjectInfo.resourceCount);
      setTopUsers(subjectInfo.topUsers);
    } catch (error) {
      console.error('Error fetching subject info:', error);
    }
  }, [subjectName]);

  useEffect(() => {
    setSubjectId(null);
    setDescription('');
    setPublicField(null);
    setBroaderField([]);
    setSiblingFields([]);
    setChildFields([]);
    setNoteCount(null);
    setResourceCount(null);
    setTopUsers([]);
    setLibrary([]); // Reset library state
    fetchSubjectInfo();
  }, [subjectName, fetchSubjectInfo]);

  useEffect(() => {
    pushPage(location.pathname);
    setShowTextArea(false);
  }, [location.pathname]);

  if (!subjectId) return (
    <div className='page-loading'>
      <Loader />
    </div>
  );

  return (
    <div className='page-container subject-page'>
      <div className='page-right'>
        <BackButton />
        <SubjectPageHeader
          editTag={editTag}
          setEditTag={setEditTag}
          subjectName={subjectName}
          publicField={publicField}
          subjectId={subjectId}
          description={description}
          setDescription={setDescription}
        />
        {!editTag && 
        <>
          <SubjectDescription description={description} noteCount={noteCount} resourceCount={resourceCount}/>
          {width <= 1089 && 
            <div className='book-tabs' style={{margin:'20px'}}>
              <button className='tab-button' onClick={() => setIsModalOpen(true)}>
                Subject overview
              </button>
            </div>
          }
          <AddNoteButton 
            subjectId={subjectId} 
            noteHint={'Take a note under this topic'} 
            showTextArea={showTextArea} 
            setShowTextArea={setShowTextArea} 
            setNotes={setNotes} 
            setLibrary={setLibrary} 
          />
        </>}     
        <TheTabs 
          notes={notes}
          setNotes={setNotes}
          library={library}
          setLibrary={setLibrary}
          subjectId={subjectId} 
          publicField={publicField}
        />
      </div>
      <div className='home-page-right'>
      {publicField && 
      <>
        <SubjectRel 
          broaderField={broaderField}
          siblingFields={siblingFields} 
          childFields={childFields} 
        />
        <RecentlyAdded subjectId={subjectId} />
        <LeaderBoard topUsers={topUsers} />
        <HalfFooter />
      </>  
      }  
      </div>
      <WholeScreenModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div style={{width:'380px'}}>
          <SubjectRel 
            broaderField={broaderField}
            siblingFields={siblingFields} 
            childFields={childFields} 
          />
          <RecentlyAdded subjectId={subjectId} />
          <LeaderBoard topUsers={topUsers} />
        </div>
      </WholeScreenModal>
    </div>
  );
};

export default SubjectPage;

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useNavigation } from '../../Context/NavigationContext';
import BackButton from '../../Components/BackButton';
import Loader from '../../Components/Loader';
import ResourceCard from '../../Components/ResourceCard/ResourceCard';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';
import TheTabs from '../../Components/ContentTabs/TheTabs';
import ResourcePageRightTabs from './Tabs';

import { fetchSingleResource, useWindowSize, convertResourceType } from '@linko/shared_utils';

// Custom hook to get window size

function ResourcePage() {
  const { id } = useParams();
  const resourceId = id;
  const location = useLocation();
  const { safeNavigate } = useContext(NavigationPromptContext);
  const { pushPage } = useNavigation();
  const openAddNote = location.state?.openAddNote;
  const [resource, setResource] = useState(null);
  const [resourceType, setResourceType] = useState(null);
  const [library, setLibrary] = useState([]);
  const [showTextArea, setShowTextArea] = useState(false);
  const [learnedUserData, setLearnedUserData] = useState(null);
  const [shareView, setShareView] = useState(null);
  const [articleContent, setArticleContent] = useState(null);
  const [embeddable, setEmbeddable] = useState(null);
  const [authorInfo, setAuthorInfo] = useState(null);
  const { width } = useWindowSize(); 
  
  // Fetch this resource info
  const fetchResource = async () => {
    const response = await fetchSingleResource(resourceId);
    setResource(response.resource);
    setResourceType(convertResourceType(response.resource.resource.type));
    setLearnedUserData(response.learnedUsers);
    setShareView(response.resource.user === null ? true : false);
    setArticleContent(response.articleContent);
    setEmbeddable(response.bookEmbeddable);
    setAuthorInfo(response.authorInfo);
  }

  useEffect(() => {
    setResource(null);
    fetchResource();
  }, [resourceId]);

  const onDelete = (id) => {
    safeNavigate('/my_linko');
  }

  useEffect(() => {
    pushPage(location.pathname);
  }, [location.pathname]);

  if (!resource) return (
    <div className='page-loading'>
      <Loader />
    </div>
  );
  
  return (
    <div className='resource-page-container'>
      <div className='resource-page-right'>
        <BackButton />
        <div className='resource-page-resource' >
          <ResourceCard
            resource={resource}
            onDelete={onDelete}
            currentResource={true}
            shareView={shareView}
            learnedUserData={learnedUserData}
          />
          {width < 1089 && 
            <ResourcePageRightTabs 
              resource={resource} 
              articleContent={articleContent} 
              embeddable={embeddable}
              authorInfo={authorInfo}
            />
          }
        </div>
        <AddNoteButton 
          subjectId={null}
          noteHint={`Take a note on this ${resourceType}.`}
          resource={resource}
          setLibrary={setLibrary} 
          showTextArea={showTextArea}
          setShowTextArea={setShowTextArea}
        />
        <TheTabs
          library={library}
          setLibrary={setLibrary} 
          resourceId={resourceId} 
          resourceType={resourceType}
          openAddNote={openAddNote}
          subjectId={null}
        />
      </div>
      {width >= 1089 && 
        <ResourcePageRightTabs 
          resource={resource} 
          articleContent={articleContent}
          embeddable={embeddable}
          authorInfo={authorInfo}
        />
      }
    </div> 
  );
}

export default ResourcePage;

import React, {useState, useEffect, useContext} from 'react';
import { useLocation }  from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { fetchResources, fetchBestUnlearned, postResourceToMine } from '@linko/shared_utils';
import { useToast } from '../../Components/ToastNotification';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';

const RecentlyAdded = ({subjectId}) => {

    const [resources, setResources] = useState([]);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { setPersistentMessage } = useToast(); 
    const location = useLocation();
    const homePage = location.pathname === '/my_linko';
    const subjectPage = location.pathname.startsWith('/s/');
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [hasMoreResources, setHasMoreResources] = useState(true);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(false);
    const signal = null;

    const fetchRecentlyAdded = async () => {
        await fetchResources( 5, 0, [], [], [], resources, setResources, setResourcesOffset, setHasMoreResources, setIsFetchingMoreResources, signal);
    };

    const fetchSubjectTopResources = async () => {
        if (subjectId) {
           const response = await fetchBestUnlearned(subjectId);
            if (response.detail === "No unlearned resources found for the given subject." ) {
                setResources([])
            } else {
                setResources(response.data);   
            }
        } 
    }

    useEffect(() => {
        setResources([]);
        if (homePage) {
            fetchRecentlyAdded();
        } else if (subjectPage && subjectId) {
            fetchSubjectTopResources();
        }
    }, [homePage, subjectPage, subjectId]); 

    const addResourceToMine = async (resourceId) => {
        await postResourceToMine(resourceId);
        safeNavigate(`/r/${resourceId}`);
        setPersistentMessage('Resource added to your library!');
    }

    
    if (!resources || resources.length === 0) {
        return null;
    }

    return (
        <div className='recently-added'>
            <div style={{display:'flex', alignItems:'flex-end', justifyContent:'space-between'}}>
                <div className='subject-rel-title'>
                    {homePage ? 'Recently Added' : subjectPage ? 'Recommend' : ''}
                </div>
            </div>
            <div className='recently-added-resources mt-10'>
                {resources && resources.map((resource) => {
                    const resourceData = resource.resource ? resource.resource : resource;
                    return (
                        <SlimResourceCard   
                            key={resourceData.id}
                            thumbnail={resourceData.image_link}  
                            type={resourceData.type}
                            title={resourceData.title}
                            author={resourceData.author}
                            year={resourceData.year}
                            onResourceClick={() => safeNavigate(`/r/${resourceData.id}`)}
                            onAddToLibrary={subjectPage ? () => addResourceToMine(resourceData.id) : null   }
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default RecentlyAdded;
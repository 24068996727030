import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FollowContext, UserContext } from '../../Context/AppContext';
import { getNotif, getNotifHistory, handleFollowAction } from '@linko/shared_utils';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';
import SlimNoteCard from '../../Components/NoteCard/SlimNoteCard';
import { BiSolidLike } from "react-icons/bi";

import { useToast } from '../../Components/ToastNotification';

const NotificationPage = () => {
  const [activeTab, setActiveTab] = useState('likes');
  const { pendingRequests, fetchFollowData } = useContext(FollowContext);
  const { fetchNotifCount } = useContext(UserContext);
  const [newNotifications, setNewNotifications] = useState([]);
  const [notificationsHistory, setNotificationsHistory] = useState([]);
  const { addToast } = useToast();
  const navigate = useNavigate();

    const fetchNewNotif = async () => {
        const response = await getNotif();
        setNewNotifications(response);
    }

    const fetchNotifHistory = async () => {
        const response = await getNotifHistory();
        setNotificationsHistory(response);
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchNotifHistory();
            await fetchNewNotif();
            // await fetchUserInfo();  
            // await fetchFollowData();
            // await fetchNotifCount();
        };
        fetchData();
    }, []);

    const goToProfile = (item) => {
        const profileCode = item.id * 912;
        const initial = item.first_name.charAt(0) + item.last_name.charAt(0);
        navigate(`/profile/${initial}${profileCode}`);
    }

    const handleAccept = async (id) => {
        const response = await handleFollowAction(id, 'confirm');
        if (response.status === 200) {
            setNewNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
            fetchFollowData();
            fetchNotifCount();
            addToast('Request accepted', 'success');
        } else {
            addToast('There was an error accepting the request, please try again later.', 'error');
        }
    }

    const handleDelete = async (id) => {
        const response = await handleFollowAction(id, 'delete');
        if (response.status === 200) {
            setNewNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
            fetchFollowData();
            fetchNotifCount();
            addToast('Request deleted', 'success');
        } else {
            addToast('There was an error deleting the request, please try again later.', 'error');
        }
    }

    const renderLikeContent = (notification) => {
        if (notification.hasOwnProperty('note')) {
        return (
            <div className='content-card' style={{cursor:'pointer'}} onClick={() => navigate(`/n/${btoa(notification.note.id)}`)}>
                <SlimNoteCard 
                    title={notification.note.title}
                    content={notification.note.note}
                    onNoteClick={() => navigate(`/n/${btoa(notification.note.id)}`)}
                />
            </div>
        );
        } else {
        return (
            <div className="notification-content resource">
            <SlimResourceCard 
                thumbnail={notification.resource.image_link}
                type={notification.resource.type}
                title={notification.resource.title}
                author={notification.resource.author}
                year={notification.resource.year}
                onResourceClick={() => navigate(`/r/${notification.resource.id}`)}
            />
            </div>
        );
        }
    };

    const renderLikes = () => {
        return (
        <>
            <h3>New</h3>
            {newNotifications.map(notification => (
            <div key={notification.id} className="notification-item">
                <p style={{marginBottom:'10px', display:'flex', alignItems:'center', gap:'5px'}}>
                    <BiSolidLike size={18} color='#257953' />
                    <strong className='hover-to-primary-color' onClick={() => goToProfile(notification.user)}>{notification.user.first_name} {notification.user.last_name}</strong> liked {notification.hasOwnProperty('note') ? 'your note' : 'a resource in your library'}
                </p>
                {renderLikeContent(notification)}
                <span className="notification-date">{notification.date}</span>
            </div>
            ))}
            {newNotifications.length === 0 && (
                <div style={{marginLeft:'20px', marginTop:'20px'}}>
                    <p style={{fontSize:'1em', color:'#999'}}>No new notifications</p>
                </div>
            )}
            {notificationsHistory.length > 0 && (
                <>
                    <div className='rx-divider-vertical' style={{marginTop:'20px'}}></div>
                    <h3>Past 30 days</h3>
                    {notificationsHistory.map(notification => (
                    <div key={notification.id} className="notification-item">
                        <p style={{marginBottom:'10px', display:'flex', alignItems:'center', gap:'5px'}}>
                            <BiSolidLike size={18} color='#257953' />
                            <strong className='hover-to-primary-color' onClick={() => goToProfile(notification.user)}>{notification.user.first_name} {notification.user.last_name}</strong> liked {notification.hasOwnProperty('note') ? 'your note' : 'a resource in your library'}
                        </p>
                        {renderLikeContent(notification)}
                        <span className="notification-date">{notification.date}</span>
                    </div>
                    ))}
                </>
            )}
        </>
        );
    };

    const renderFollowRequests = () => {
        if (pendingRequests.length === 0) {
            return (
                <div style={{marginLeft:'20px', marginTop:'20px'}}>
                    <p style={{fontSize:'1em', color:'#999'}}>No follow requests</p>
                </div>
            );
        }
        return (
        <>
            {pendingRequests.map(request => (
            <div key={request.id} className="request-item">
                <p>
                <strong className='hover-to-green'>{request.first_name} {request.last_name}</strong> wants to follow you
                </p>
                <div className='action-buttons'>
                    <button className='accept-button' onClick={() => handleAccept(request.id)}>Accept</button>
                    <button className='delete-button' onClick={() => handleDelete(request.id)}>Delete</button>
                </div>
            </div>
            ))}
        </>
        );
    };

    return (
        <div className="profile-page">
        <h1>Notifications</h1>
        <div className="tabs">
            <button
            className={activeTab === 'likes' ? 'active' : ''}
            onClick={() => setActiveTab('likes')}
            >
            LIKES
            </button>
            <button
            className={activeTab === 'followRequests' ? 'active' : ''}
            onClick={() => setActiveTab('followRequests')}
            style={{position:'relative'}}
            >
            FOLLOW REQUEST
            {pendingRequests.length > 0 && <span className="notification-dot"></span>}
            </button>
        </div>
        <div className="notifications-list">
            {activeTab === 'likes' && renderLikes()}
            {activeTab === 'followRequests' && renderFollowRequests()}
        </div>
        </div>
    );
};

export default NotificationPage;

import React, {useState, useEffect, useContext, useRef} from "react";
import { useLocation } from "react-router-dom";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { UserContext } from "../../Context/AppContext";
import ResourceCardActions from "./ResourceCardActions";
import ResourceTypeIcon from "./ResourceTypeIcon";
import ResourceProgress from "./ResourceProgress";
import CardSubject from "../CardSubject";
import { postResourceToMine } from "@linko/shared_utils";
import LearnedUsers from "./LearnedUsers";

const ResourceCard = ({ 
    resource , 
    onDelete, 
    onUpdate, 
    shareView, 
    currentResource,
    learnedUserData
}) => {
    let id, title, author, type, description, month, year, url, platform, image_link;
    if (resource && resource.resource) {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource.resource);
    } else {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource);
    }
    const resourceCardRef = useRef(null);
    const [subject, setSubject] = useState([]);
    const isBook = type === "bo";
    const imageLink = image_link && image_link.trim() !== "" 
        ? image_link.startsWith("http:")
            ? "https:" + image_link.substring(5)
            : image_link
        : null;
    const location = useLocation();
    const { userInfo } = useContext(UserContext);
    const [hideImage, setHideImage] = useState(false);
    const { safeNavigate } = useContext(NavigationPromptContext);

    useEffect(() => {
        if (resource?.user !== null) {
            setSubject(resource.user_knowledge);
        } else {
            setSubject(resource.resource.knowledge);
        }
    }, [resource, userInfo]);

    const handleAddToLibrary = async () => {
        postResourceToMine(id).finally(() => {
            if (location.pathname.startsWith("/r/")) 
            { window.location.reload(); }
            else safeNavigate(`/r/${id}`);
        });
    }

    // Hide image when resource card is small
    useEffect(() => {
        const checkWidth = () => {
            if (resourceCardRef.current) {
                setHideImage(resourceCardRef.current.offsetWidth < 425);
            }
        };
        checkWidth();
        window.addEventListener('resize', checkWidth);

        return () => {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    return (
        <div id="resource-card" ref={resourceCardRef}>
            <ResourceTypeIcon type={type} size={24}/>
            <div className="resource-text-info">
                <div className="resource-title-author">
                    <div onClick={() => safeNavigate(`/r/${id}`)}>
                        <p className={currentResource ? 'no-hover' : 'resource-title'}>{title}</p>
                    </div>
                    <div className="author" style={{columnGap:'0px'}}>
                        {author && <span>{author}</span>}
                        {author && year && <span>, {year}</span>}
                        {author && year && month && <span>.{month.toString().padStart(2, '0')}</span>}
                        {platform && platform !== 'null' && <span> , {platform} </span> }
                    </div>
                </div>
                {!shareView && <ResourceProgress id={id} learnHistory={resource} onUpdate={onUpdate}/>}
                <CardSubject
                    subject={subject}
                    setSubject={setSubject}
                    shareView={shareView}
                    resource={resource}
                    resourceId={id}
                    onUpdate={onUpdate}
                />
                {currentResource && learnedUserData &&
                    <LearnedUsers learnedUserData={learnedUserData}/>
                }
                {currentResource && 
                    <ResourceCardActions
                        link={url}
                        id={id}
                        onDelete={onDelete} 
                        isBook={isBook}
                        shareView={shareView}
                        handleAddToLibrary={handleAddToLibrary}
                        currentResource={currentResource}
                    />
                }
            </div>
            {imageLink !== null && !hideImage && (
                <div className="resource-image">
                    <img
                        src={imageLink}
                        alt={title}
                    />
                </div>
            )}
            {!currentResource && 
                <ResourceCardActions
                    link={url}
                    id={id}
                    onDelete={onDelete}
                    isBook={isBook}
                    shareView={shareView}
                    handleAddToLibrary={handleAddToLibrary}
                    currentResource={currentResource}
                />
            }
        </div>
    );
};

export default ResourceCard;

import React,{useState , useEffect , useRef, useContext, useCallback} from 'react';
import { UserSubjectContext } from '../Context/AppContext';
import { NavigationPromptContext } from '../Context/NavigationPromptContext';
import { TiDelete } from "react-icons/ti";
import { GoPlusCircle } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import Loader from './Loader';
import { useToast } from './ToastNotification';
import { FaRegQuestionCircle } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import { searchKnowledge, putNote, putMyResource, fetchUserSubject } from '@linko/shared_utils';

const CardSubject = ({
    subject, 
    setSubject, 
    shareView, 
    resource, 
    resourceId,
    onUpdate, 
    noteId, 
    updateSingleNote
    }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [hasExactMatch, setHasExactMatch] = useState(false);
    const { addToast } = useToast();
    const { userSubject } = useContext(UserSubjectContext);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const textareaRef = useRef(null);
    const timeoutId = useRef();;
    const [suggested, setSuggested] = useState([]);

    useEffect(() => {
        const resourceTopSubject = userSubject.filter(item => item.is_linked === true).sort((a, b) => b.resource_count - a.resource_count).slice(0, 5);
        const noteTopSubject = userSubject.filter(item => item.is_linked === true).sort((a, b) => b.note_count - a.note_count).slice(0, 5);
        const myTags = userSubject.filter(item => item.is_linked === false).sort((a, b) => b.resource_count - a.resource_count); 
        const topSubject = noteId ? noteTopSubject : resourceTopSubject;
        const defaultSelections = myTags.concat(topSubject).filter(topSubject => !subject?.some(k => k.id === topSubject.id));
        setSuggested(defaultSelections);
    }, [userSubject, subject, noteId]);

    useEffect(() => { 
        const fetchSubject = async () => {
            if (searchTerm !== '') {
                timeoutId.current && clearTimeout(timeoutId.current);
                timeoutId.current = setTimeout(async () => {
                    try {
                        const response = await searchKnowledge(searchTerm);
                        setSearchResults(response);
                        const exactMatch = response.some(item => item.name && item.name.toLowerCase().replace(/\s/g, '') === searchTerm.toLowerCase().replace(/\s/g, ''));
                        setHasExactMatch(exactMatch);
                    } catch (error) {
                        console.error('Error fetching subject:', error.response ? error.response.data : error.message);
                    }
                }, 1000);
            } else {
                setSearchResults([]);
                setHasExactMatch(false);
            }
        };
        fetchSubject();
    }, [searchTerm]);  

    useEffect(() => {
        if (isSearching && textareaRef.current) {
          textareaRef.current.focus();
        }
      }, [isSearching]);

    const handleDeleteSubject = async (id) => {
        setSubject(prevSubject => {
            const newSubject = prevSubject.filter(k => k.id !== id);
            updateSubject(newSubject);
            return newSubject;
        });
    };
    
    const handleResultClick = async (subject) => {
        const subjectArray = Array.isArray(subject) ? subject : [];

        if (subjectArray.some(k => k.id === subject.id)) {
            alert('This subject is already added');
            return;
        }
        setSubject(prevSubject => {
            const updatedSubject = [...prevSubject, subject];
            updateSubject(updatedSubject);
            return updatedSubject;
        });
        setSearchTerm('');
        setSearchResults([]);
        setIsSearching(false);
    };

    const handleCustomLabelClick = useCallback(async () => {
        const subject = { id: null, name: searchTerm };
        setSubject(prevSubject => {
            const updatedSubject = [...prevSubject, subject];
            updateSubject(updatedSubject);
            return updatedSubject;
        });
        await new Promise(resolve => setTimeout(resolve, 1000));
        setSearchTerm('');
        setSearchResults([]);
        setIsSearching(false);
        addToast('Custom topic created.', { appearance: 'success' });
        await fetchUserSubject();

    }, [fetchUserSubject, searchTerm]);

    const updateSubject = async (subject) => {
        if (noteId) {
            await putNote(noteId, null, subject.map(k => k.name), null);
        } else {
            await putMyResource(resourceId, null, null, subject.map(k => k.name));
        }
        setSubject(subject);
        if (onUpdate) {
            const updatedResourceDetails = {
                ...resource, 
                user_knowledge: subject
            };
            onUpdate(updatedResourceDetails);
        }
        if (updateSingleNote) {
            updateSingleNote(noteId);
        }
    }

  return (
    <div className="note-card-subjects">
        <div className="subjects" style={{marginBottom: noteId ? '16px' : '0px' }}>
            {subject && 
                [...subject]
                .filter(k => !shareView || (shareView && k.is_linked))
                .sort((a, b) => a.is_linked === b.is_linked ? 0 : a.is_linked ? 1 : -1)
                .map((k) => (
                    <div className={`subject-tag ${k.is_linked ? 'studied' : 'cus-label'}`} key={k.id}>
                            <div onClick={() => safeNavigate(`/s/${encodeURIComponent(k.name)}`)}>
                                {k.name}
                            </div>
                            {!shareView && 
                                <TiDelete 
                                    className='delete-subject-icon' 
                                    onClick={() => {
                                        handleDeleteSubject(k.id);
                                    }}
                                />
                            }
                    </div>
                ))
            }
            {!shareView && 
            <>
            {!isSearching ? (
                <div className='add-note-subject'>
                    <GoPlusCircle 
                        className='add-subject-icon' 
                        style={{marginLeft:'0px'}}
                        onClick={() => setIsSearching(true)}
                    />
                </div>
                ) : (
                <div className='searching'>
                        <RxCross2 
                            className='minus-icon' 
                            onClick={() => setIsSearching(false)}
                        />
                        <textarea 
                            className='add-note-subject-textarea' 
                            value={searchTerm}
                            ref={textareaRef}
                            placeholder="Search" 
                            onChange={e => {
                                setSearchTerm(e.target.value);
                                setSearchResults([]);
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
            )}
            {isSearching && (
                <div className='subject-search-result-area'>
                    {searchTerm === '' ? (
                        <div className='subject-search-result'>
                            <div className='subjects'>
                                {suggested.map((result) => (
                                    <div className={`subject-tag ${result.is_linked ? 'studied' : 'cus-label'}`} key={result.id} onClick={() => handleResultClick(result)}>
                                        <p>{result.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) 
                    : 
                    (   <div className='subject-search-result'>
                            {searchResults?.length === 0 ? <Loader size={20}/> :
                            <>
                            {!hasExactMatch &&(
                                <div className='suggesting-cus-label'>
                                    <p>Create a custom tag</p>
                                    <Tippy 
                                        interactive={true}
                                        content={(
                                            <p>Custom tags are only visible to yourself and will not be included in the subject graph. </p>
                                        )}
                                        placement="right"
                                        arrow={true}
                                        theme='light-border'
                                        delay={[0, 0]} 
                                    >
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <FaRegQuestionCircle/>
                                        </div>
                                    </Tippy>
                                    <p>: </p>
                                    <div className='cus-label'>
                                        <p onClick={handleCustomLabelClick}>{searchTerm}</p>
                                    </div>
                                </div>
                            )}
                            <div className='subjects'>
                                {searchResults?.map((result) => (
                                    <div className={`subject-tag ${result.is_linked ? 'studied' : 'cus-label'}`} key={result.id} onClick={() => handleResultClick(result)}>
                                        <p>{result.name}</p>
                                    </div>
                                ))}
                            </div>
                            </>
                            }
                        </div>
                    )
                    }
                </div>
            )}
            </>
            }
        </div>
    </div>
)};

export default CardSubject;

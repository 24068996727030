import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const fetchMyFeed = async (limit, libraryOffset, subject_id, resource_id, user_id, setLibrary, setLibraryOffset, setHasMoreLibrary, setIsFetchingMoreLibrary) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        const headers = { Authorization: `Bearer ${accessToken}` };
        const payload = {
            limit,
            offset: libraryOffset,
        };
        if (subject_id !== null && subject_id !== undefined) {
            payload.subject_id = subject_id;
        }
        if (user_id !== null && user_id !== undefined) {
            payload.user_id = user_id;
        }
        if (resource_id !== null && resource_id !== undefined) {
            payload.resource_id = resource_id;
        }
        const apiEndpoint = user_id ? '/api/feed/user_feed/' : '/api/feed/';
        
        const response = await axios.get(apiEndpoint, {
            headers,
            params: payload,
        });
        
        const results = Array.isArray(response.data.results) ? response.data.results : [];
        setLibrary(prevLibrary => [...prevLibrary, ...results]);
        setHasMoreLibrary(results.length === limit);
        
        if (results.length > 0) {
            setLibraryOffset(prevOffset => prevOffset + limit);
        }
    } catch (error) {
        console.error('Failed to fetch content feed:', error);
    } finally {
        setIsFetchingMoreLibrary(false);
    }
};
import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

const getAuthHeader = async () => {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
        throw new Error('No access token found');
    }
    return { Authorization: `Bearer ${accessToken}` };
};

export const putMyResource = async (resource_id, progress, total_length, knowledge_name_list) => {
    try {
        const headers = await getAuthHeader();
        const payload = {
            ...(progress !== undefined && { progress }),
            ...(total_length !== undefined && { total_length }),
            ...(knowledge_name_list !== undefined && { knowledge_name_list })
        };

        const response = await axios.put(`/api/resource/${resource_id}/update_resource_learn/`, payload, { headers });
        return response.data;
    } catch (error) {
        console.error('Error updating resource:', error);
        throw error;
    }
};

export const putResource = async (resource_id, title, author, type, year, month) => {
    try {
        const headers = await getAuthHeader();
        const payload = {
            title,
            ...(author && { author }),
            ...(type && { type }),
            ...(year && { year }),
            ...(month && { month })
        };

        const response = await axios.put(`/api/resource/${resource_id}/`, payload, { headers });
        return response;
    } catch (error) {
        console.error('Error updating resource details:', error);
        throw error;
    }
};

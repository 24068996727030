import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const putNote = async (noteId, noteData, subject_name_list, resource_id) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }

        const payload = { };

        if (noteData !== null && noteData !== undefined) {
            payload.note = noteData
        }

        if (subject_name_list !== null && subject_name_list !== undefined) {
            payload.knowledge_name_list = subject_name_list;
        }

        if (resource_id !== null && resource_id !== undefined) {
            payload.resource_id = resource_id;
        }

        const response = await axios.put(`/api/note/${noteId}/`, payload, {
            headers: { Authorization: `Bearer ${accessToken}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating note:', error.response?.data || error.message);
        if (error.config) {
            console.error('Request config:', error.config);
        }
        if (error.response) {
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
        }
        throw error;
    }
};
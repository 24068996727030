import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Editor, EditorState, ContentState, convertToRaw, convertFromRaw, RichUtils, getDefaultKeyBinding, CompositeDecorator, Modifier, ContentBlock, genKey } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { List } from 'immutable';
// icons 
import { LuHeading } from "react-icons/lu";
import { BsChatLeftQuote } from "react-icons/bs";
import { VscListUnordered } from "react-icons/vsc";
import { GoListOrdered } from "react-icons/go";
import { BiBold } from "react-icons/bi";
import { PiTextItalicBold } from "react-icons/pi";
import { BiLink } from "react-icons/bi";

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ color: '#3b5998', textDecoration: 'underline' }}>
      {props.children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
};

const customLineBreakDecorator = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const blockType = contentBlock.getType();
  const isListItem = blockType === 'unordered-list-item' || blockType === 'ordered-list-item';

  let matchArr, start;
  const regex = isListItem ? /\n/g : /\n\n/g;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + (isListItem ? 1 : 2));
  }
};

const LineBreakSpan = (props) => {
  return <br />;
};

const StyleButton = ({ onToggle, style, label, active }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <span 
      className="RichEditor-styleButton" 
      onMouseDown={onMouseDown}
      style={{ color: active ? '#267953' : '#999999' }}
    >
      {label}
    </span>
  );
};

const Image = (props) => {
  const { src } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <div contentEditable={false} style={{ position: 'relative' }}>
      <img src={src} alt="" style={{ maxWidth: '90%', height: 'auto' }} />
    </div>
  );
};

const insertImage = (contentState, src) => {
  const contentStateWithEntity = contentState.createEntity(
    'IMAGE',
    'IMMUTABLE',
    { src: src }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newContentState = Modifier.insertText(
    contentStateWithEntity,
    contentStateWithEntity.getSelectionAfter(),
    ' ',
    undefined,
    entityKey
  );
  return newContentState;
};

const insertImageAtIndex = (contentState, src, index) => {
  const blockMap = contentState.getBlockMap();
  let targetBlock;
  let targetOffset;

  // Find the block and offset where the image should be inserted
  let currentIndex = 0;
  blockMap.forEach((block) => {
    const blockLength = block.getLength();
    if (currentIndex + blockLength > index) {
      targetBlock = block;
      targetOffset = index - currentIndex;
      return false; // break the loop
    }
    currentIndex += blockLength + 1; // +1 for the newline character
  });

  if (!targetBlock) {
    // If we couldn't find the right block, insert at the end
    targetBlock = blockMap.last();
    targetOffset = targetBlock.getLength();
  }

  // Create the image entity
  const contentStateWithEntity = contentState.createEntity(
    'IMAGE',
    'IMMUTABLE',
    { src: src }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  // Insert the image
  const newContentState = Modifier.insertText(
    contentStateWithEntity,
    contentState.getSelectionAfter().merge({
      anchorKey: targetBlock.getKey(),
      focusKey: targetBlock.getKey(),
      anchorOffset: targetOffset,
      focusOffset: targetOffset,
    }),
    ' ',
    undefined,
    entityKey
  );

  return newContentState;
};

const isSelectionOnImage = (editorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const block = content.getBlockForKey(startKey);
  const entityKey = block.getEntityAt(startOffset);
  
  if (entityKey) {
    const entity = content.getEntity(entityKey);
    return entity.getType() === 'IMAGE';
  }
  return false;
};

const RichEditor = ({ value, onChange, placeholder }) => {
  const [editorState, setEditorState] = useState(() => {
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: customLineBreakDecorator,
        component: LineBreakSpan,
      },
      {
        strategy: (contentBlock, callback, contentState) => {
          contentBlock.findEntityRanges(
            (character) => {
              const entityKey = character.getEntity();
              return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'IMAGE'
              );
            },
            callback
          );
        },
        component: Image,
      },
    ]);
    return EditorState.createEmpty(decorator);
  });
  const editorRef = useRef(null);
  const isInternalChange = useRef(false);
  const createContentState = useCallback((content) => {
    if (!content) return ContentState.createFromText('');
    
    try {
      const imageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g;
      const images = [];
      let match;
      let lastIndex = 0;
      while ((match = imageRegex.exec(content)) !== null) {
        images.push({ 
          alt: match[1], 
          src: match[2], 
          index: match.index,
          length: match[0].length
        });
      }

      // Convert markdown to raw draft object
      const rawDraftObject = markdownToDraft(content);

      if (!rawDraftObject || !rawDraftObject.blocks) {
        console.error('Invalid rawDraftObject:', rawDraftObject);
        return ContentState.createFromText(content);
      }

      // Create content state from raw object
      let contentState = convertFromRaw(rawDraftObject);

      // Insert images at their original positions
      images.reverse().forEach(image => {
        contentState = insertImageAtIndex(contentState, image.src, image.index);
      });

      return contentState;
    } catch (error) {
      console.error('Error converting markdown to draft:', error);
      return ContentState.createFromText(content);
    }
  }, []);

  useEffect(() => {
    if (!isInternalChange.current && value) {
      const newContentState = createContentState(value);
      const newEditorState = EditorState.createWithContent(newContentState, editorState.getDecorator());
      setEditorState(newEditorState);
    }
    isInternalChange.current = false;
  }, [value, createContentState]);

  const handlePastedText = (text, html, editorState) => {
    if (text.match(/^https?:\/\/.+\.(jpeg|jpg|gif|png)$/)) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'IMAGE',
        'IMMUTABLE',
        { src: text }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
      
      const newContentState = Modifier.replaceText(
        newEditorState.getCurrentContent(),
        newEditorState.getSelection(),
        ' ',
        newEditorState.getCurrentInlineStyle(),
        entityKey
      );
      
      const finalEditorState = EditorState.push(newEditorState, newContentState, 'insert-characters');
      handleEditorChange(finalEditorState);
      return 'handled';
    }
    
    return 'not-handled';
  };

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    
    let markdown = draftToMarkdown(rawContent, {
      entityItems: {
        IMAGE: {
          open: () => '',
          close: (entity) => `![](${entity.data.src})`,
        },
      },
    });

    // Remove extra spaces around image markdown
    markdown = markdown.replace(/\s!\[\]\(/g, '![](');

    isInternalChange.current = true;

    onChange(markdown);
  };

  const handleKeyCommand = (command, editorState) => {
    if (command === 'insert-new-line-after-image') {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const key = selection.getStartKey();
      const blockMap = contentState.getBlockMap();
      const block = blockMap.get(key);
      
      // Create a new empty block
      const newBlock = new ContentBlock({
        key: genKey(),
        type: 'unstyled',
        text: '',
        characterList: List()
      });
      
      // Insert the new block after the current one
      const newBlockMap = blockMap.set(key, block).set(newBlock.getKey(), newBlock);
      const newContentState = contentState.merge({
        blockMap: newBlockMap,
        selectionAfter: selection.merge({
          anchorKey: newBlock.getKey(),
          anchorOffset: 0,
          focusKey: newBlock.getKey(),
          focusOffset: 0,
          isBackward: false,
        }),
      });
      
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
      handleEditorChange(newEditorState);
      return 'handled';
    }

    if (command === 'split-block-and-reset-type') {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      
      // Split the block
      let newContentState = Modifier.splitBlock(contentState, selection);
      
      // Get the key of the new block
      const blockAfterSplit = newContentState.getBlockAfter(selection.getStartKey());
      
      // Change the type of the new block to unstyled
      newContentState = Modifier.setBlockType(
        newContentState,
        selection.merge({
          anchorKey: blockAfterSplit.getKey(),
          focusKey: blockAfterSplit.getKey(),
          anchorOffset: 0,
          focusOffset: 0,
        }),
        'unstyled'
      );
      
      const newEditorState = EditorState.push(editorState, newContentState, 'split-block');
      
      handleEditorChange(EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()));
      return 'handled';
    }

    // Handle toggle commands
    if (command.startsWith('toggle-')) {
      const blockType = command.replace('toggle-', '');
      let newEditorState = RichUtils.toggleBlockType(editorState, blockType);

      // Remove the triggering characters
      const selection = newEditorState.getSelection();
      const content = newEditorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const text = block.getText();

      let charsToRemove = 0;
      if (text.startsWith('#')) charsToRemove = 1;
      if (text.startsWith('##')) charsToRemove = 2;
      if (text.startsWith('* ')) charsToRemove = 2;
      if (text.startsWith('1. ')) charsToRemove = 3;
      if (text.startsWith('> ')) charsToRemove = 2;

      if (charsToRemove > 0) {
        const newContent = Modifier.replaceText(
          content,
          selection.merge({
            anchorOffset: 0,
            focusOffset: charsToRemove,
          }),
          ''
        );
        newEditorState = EditorState.push(newEditorState, newContent, 'remove-range');
      }

      handleEditorChange(newEditorState);
      return 'handled';
    }

    if (command === 'backspace' || command === 'delete') {
      if (isSelectionOnImage(editorState)) {
        const selection = editorState.getSelection();
        const content = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const block = content.getBlockForKey(startKey);
        
        // Create a new selection that includes the image
        const imageSelection = selection.merge({
          anchorOffset: startOffset,
          focusOffset: startOffset + 1
        });
        
        // Remove the image
        const newContent = Modifier.removeRange(content, imageSelection, 'backward');
        const newEditorState = EditorState.push(editorState, newContent, 'remove-range');
        
        handleEditorChange(newEditorState);
        return 'handled';
      }
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const keyBindingFn = (e) => {
    if (e.key === 'Enter') {
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      
      // Check if the cursor is at the end of a block
      if (selection.getAnchorOffset() === block.getLength()) {
        const entityKey = block.getEntityAt(block.getLength() - 1);
        if (entityKey) {
          const entity = content.getEntity(entityKey);
          if (entity.getType() === 'IMAGE') {
            return 'insert-new-line-after-image';
          }
        }
      }
    }

    if (e.key === ' ') {
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const text = block.getText();

      let newBlockType = null;
      let charsToRemove = 0;

      if (text === '#') { newBlockType = 'header-two'; charsToRemove = 1; }
      else if (text === '*') { newBlockType = 'unordered-list-item'; charsToRemove = 1; }
      else if (text === '1.') { newBlockType = 'ordered-list-item'; charsToRemove = 2; }
      else if (text === '>') { newBlockType = 'blockquote'; charsToRemove = 1; }

      if (newBlockType) {
        e.preventDefault();  // Prevent the space from being inserted
        
        // Remove the triggering characters
        let newContent = Modifier.replaceText(
          content,
          selection.merge({
            anchorOffset: 0,
            focusOffset: charsToRemove,
          }),
          ''
        );

        // Change the block type
        newContent = Modifier.setBlockType(
          newContent,
          newContent.getSelectionAfter(),
          newBlockType
        );

        const newEditorState = EditorState.push(editorState, newContent, 'change-block-type');
        handleEditorChange(newEditorState);
        return 'handled';
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const blockType = block.getType();

      if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
        const newEditorState = RichUtils.onTab(e, editorState, 4);
        handleEditorChange(newEditorState);
        return 'handled';
      }
    } else if (e.key === 'Backspace') {
      return 'backspace';
    } else if (e.key === 'Delete') {
      return 'delete';
    }

    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    handleEditorChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    handleEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const addLink = () => {
    const selection = editorState.getSelection();
    const link = window.prompt('Enter a URL:');
    if (!link) {
      return;
    }
    
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: link }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
    let newEditorState;
    
    if (selection.isCollapsed()) {
      // If no text is selected, insert the URL as the link text
      const textWithEntity = Modifier.insertText(
        contentStateWithEntity,
        selection,
        link,
        null,
        entityKey
      );
      newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
    } else {
      // If text is selected, apply the link to the selection
      const withLink = Modifier.applyEntity(
        contentStateWithEntity,
        selection,
        entityKey
      );
      newEditorState = EditorState.push(editorState, withLink, 'apply-entity');
    }
    
    setEditorState(newEditorState);
    
    // Force update after state change
    setTimeout(() => {
      handleEditorChange(newEditorState);
    }, 0);
  };

  const focusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };
  
  const isStyleActive = (style) => {
    if (style.startsWith('header-') || style === 'blockquote' || style.endsWith('-list-item')) {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    }
    return editorState.getCurrentInlineStyle().has(style);
  };

  return (
    <div className="RichEditor-root">
      <div className="RichEditor-controls">
        <StyleButton onToggle={toggleBlockType} style="header-two" label={<LuHeading />} active={isStyleActive('header-two')} />
        <StyleButton onToggle={toggleBlockType} style="blockquote" label={<BsChatLeftQuote />} active={isStyleActive('blockquote')} />
        <StyleButton onToggle={toggleInlineStyle} style="BOLD" label={<BiBold />} active={isStyleActive('BOLD')} />
        <StyleButton onToggle={toggleInlineStyle} style="ITALIC" label={<PiTextItalicBold />} active={isStyleActive('ITALIC')} />
        <StyleButton onToggle={toggleBlockType} style="unordered-list-item" label={<VscListUnordered />} active={isStyleActive('unordered-list-item')} />
        <StyleButton onToggle={toggleBlockType} style="ordered-list-item" label={<GoListOrdered />} active={isStyleActive('ordered-list-item')} />
        <StyleButton onToggle={addLink} style="LINK" label={<BiLink />} active={false} />
      </div>
      <div onClick={focusEditor} className="RichEditor-content">
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={handleEditorChange}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          handlePastedText={handlePastedText}
        />
      </div>
    </div>
  );
};

export default React.memo(RichEditor);
import React, { createContext, useState, useContext, useEffect } from 'react';
import { NavigationPromptContext } from './NavigationPromptContext';
import { useNavigate, useLocation } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const [historyStack, setHistoryStack] = useState([]);
    const { safeNavigate, hasUnsavedChanges } = useContext(NavigationPromptContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setHistoryStack(prevStack => {
            // Only add the new location if it's different from the last one
            if (prevStack.length === 0 || prevStack[prevStack.length - 1] !== location.pathname) {
                return [...prevStack, location.pathname];
            }
            return prevStack;
        });
    }, [location]);

    const pushPage = (page) => {
        navigate(page);
    };

    const goBack = () => {
        if (historyStack.length > 1) {
            // Remove the current page from the history stack
            setHistoryStack(prevStack => prevStack.slice(0, -1));
            // Find the last unique page that's different from the current one
            let index = historyStack.length - 2;
            while (index > 0 && historyStack[index] === historyStack[historyStack.length - 1]) {
                index--;
            }
            const previousPage = historyStack[index];
            safeNavigate(previousPage);
        } else {
            // Always navigate to '/my_linko' if there's no previous page
            safeNavigate('/my_linko');
        }
    };

    // Update canGoBack to always be true
    const canGoBack = true;

    return (
        <NavigationContext.Provider value={{ pushPage, goBack, canGoBack, historyStack }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => useContext(NavigationContext);

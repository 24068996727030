import React, { useState, useEffect, useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useNavigate } from 'react-router-dom';
import { BiSolidLike } from "react-icons/bi";
import Avatar from 'react-avatar';

const NoteLikeModal = ({ isOpen, data, onOk, onCancel }) => {
    const context = useContext(NavigationPromptContext);
    const navigate = useNavigate();
    const safeNavigate = context?.safeNavigate || navigate;

    const [users, setUsers] = useState(data.data);
    const [count, setCount] = useState(parseInt(data.count));
    const [type, setType] = useState(data.type);

    useEffect(() => {
        if (isOpen) {
            setUsers(data.data);
            setCount(parseInt(data.count));
            setType(data.type);
        }
    }, [isOpen, data]);

    const goToProfile = (id, firstName, lastName) => {
        const profileCode = id * 912;
        const initial = firstName.charAt(0) + lastName.charAt(0);
        onCancel();
        safeNavigate(`/profile/${initial}${profileCode}`);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" onClick={onCancel}>
            <div className="modal-content note-likes" onClick={(e) => e.stopPropagation()}>
                <div className='name-list'>
                    {users?.map((item) => (
                        <div className='note-likers' key={item.id}>
                            <div style={{display:'flex', alignItems:'center', columnGap:'15px'}}>
                                <Avatar name={`${item.first_name} ${item.last_name}`} size="25" round={true} color="#48c78e" className='avatar'/>
                                <p key={item.id} onClick={() => goToProfile(item.id, item.first_name, item.last_name)}>{item.first_name}</p>
                            </div>
                            {type === 'noteLikes' ? <BiSolidLike /> : null}
                        </div>
                    ))}
                    { type === 'learnedUsers' && count > 0  && 
                        <div className='learned-user-count'>
                            And {count} private user{count > 1 ? 's' : ''}.
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default NoteLikeModal;
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Tippy from '@tippyjs/react';
// Context
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
// Components
import RichEditor from './RichEditor';
import Loader from '../Loader';
import FocusMode from './FocusMode';
import PrivateToggle from './PrivateToggle';
import LinkedResource from './LinkedResource';
// Icons
import { CgExpand } from "react-icons/cg";

const EditNoteCard = ({
  isLoading,
  showTextArea,

  handleSubmit,
  handleCancel,
  updateSingleNote,

  title,
  setTitle,
  note,
  setNote,
  isPrivate,
  setIsPrivate,
  noteResource,
  setNoteResource,
}) => {

  const initialTitleRef = useRef(title);
  const initialNoteRef = useRef(note);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { setUnsavedChanges } = useContext(NavigationPromptContext);
  const [editorContent, setEditorContent] = useState(null);

  useEffect(() => {
    if (note) {
      setEditorContent(note);
    } else {
      setEditorContent('');
    }
  }, [note]);

  useEffect(() => {
    initialNoteRef.current = note;
    initialTitleRef.current = title;
  }, [note, title]);

  useEffect(() => {
    initialTitleRef.current = title;
    initialNoteRef.current = note;
  }, [showTextArea]);

  const checkForUnsavedChanges = useCallback((noteContent, titleContent) => {
    const initialNote = initialNoteRef.current;
    const initialTitle = initialTitleRef.current;
    if (noteContent !== initialNote || (titleContent && titleContent?.trim() !== (initialTitle && initialTitle?.trim()))) {
      setUnsavedChanges(true);
    } else if (!noteContent?.trim() && (!titleContent || !titleContent?.trim())) {
      setUnsavedChanges(false);
    }
  }, [setUnsavedChanges]);

  const handleEditorChange = useCallback((markdown) => {
    setNote(markdown);
    setEditorContent(markdown);
    checkForUnsavedChanges(markdown, title);
  }, [setNote, title, checkForUnsavedChanges]);

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    checkForUnsavedChanges(note, newTitle);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleTitlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');
    const newTitle = pastedText.replace(/\n/g, ' ');
    setTitle(newTitle);
  };

  // useEffect(() => {
  //   console.log('EditNoteCard rendering with editorContent:', editorContent);
  // }, [editorContent]);

  if (isFullScreen) {
    return (
      <FocusMode 
        handleSubmit={handleSubmit}
        title={title}
        handleTitleChange={handleTitleChange}
        handleEnterKey={handleEnterKey}
        handleTitlePaste={handleTitlePaste}
        setIsFullScreen={setIsFullScreen}
        isPrivate={isPrivate}
        handlePrivateChange={() => setIsPrivate(!isPrivate)}
        handleCancel={handleCancel}
        isLoading={isLoading}
        note={note}
        handleEditorChange={handleEditorChange}
        editorContent={editorContent}
      />
    );
  }

  return (
    <div className='add-note-card'>
      {isLoading ? <Loader /> : (
        <>
          <form onSubmit={handleSubmit}>
            <div className='add-note-card-header'>
              <textarea 
                value={title} 
                onChange={handleTitleChange}
                onKeyDown={handleEnterKey} 
                onPaste={handleTitlePaste}
                placeholder='Title' 
                className='add-note-title'
                rows={1}  
              />
              <Tippy 
                content="Enter focus mode"
                placement="bottom"
                arrow={true}
                theme='light-border'
                hideOnClick={true} 
                delay={[0, 0]} 
              >
                <div>
                  <CgExpand 
                    title='Enter focus mode'
                    size={50} 
                    onClick={() => setIsFullScreen(true)}
                  />
                </div>
              </Tippy>
            </div>  
            <RichEditor 
              key={note?.id}
              value={editorContent || ''}
              onChange={handleEditorChange}
              placeholder='Write your note...'
            />
          </form>
          <div className='cancel-or-submit'>
            <LinkedResource noteResource={noteResource} setNoteResource={setNoteResource} noteId={note?.id} updateSingleNote={updateSingleNote}/>
            <PrivateToggle isPrivate={isPrivate} handlePrivateChange={() => setIsPrivate(!isPrivate)} />
            <button onClick={handleCancel} className='linko-button linko-button--cancel mr-10' >Cancel</button>
            <button onClick={handleSubmit} className='linko-button linko-button--primary' disabled={isLoading}>Save</button>
          </div>
        </>
      )}
    </div>
  );
};

export default EditNoteCard;
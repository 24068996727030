import axios from "axios";
import storageUtils from "../storageUtils.js";

let isRefreshing = false;
let failedQueue = [];

// axios.defaults.baseURL = "http://127.0.0.1:8000/";
// axios.defaults.baseURL = "http://192.168.1.186:8000/";

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => response,

  async (error) => {
    const originalRequest = error.config;
    const status = error.response?.status;
    const errorData = error.response?.data;

    // Check if the error is due to an invalid token
    const isInvalidTokenError = 
      status === 401 && 
      errorData?.code === "token_not_valid" &&
      errorData?.messages?.[0]?.message === "Token is invalid or expired";

    // Check if the request is for token refresh
    if (originalRequest?.url === "/api/auth/refresh/") {
      await storageUtils.removeItem("access_token");
      await storageUtils.removeItem("refresh_token");
      delete axios.defaults.headers.common["Authorization"];
      failedQueue = [];
      return Promise.reject(error);
    }

    const publicRoutes = [
      "/api/auth/register/",
      "/api/auth/activate/",
      "/api/auth/reset_password/",
      "/api/auth/login/",
      "/api/auth/logout/",
    ];

    if ((status === 401 && !publicRoutes.includes(originalRequest?.url)) || isInvalidTokenError) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      try {
        const refreshToken = await storageUtils.getItem("refresh_token");
        if (!refreshToken) {
          throw new Error("No refresh token available");
        }
        
        // Create a new axios instance for the refresh call without the default headers
        const refreshAxios = axios.create();
        delete refreshAxios.defaults.headers.common["Authorization"];
        const response = await refreshAxios.post("/api/auth/refresh/", 
          { refresh: refreshToken },
          { headers: { "Content-Type": "application/json" } }
        );

        const { access, refresh } = response.data;
        await storageUtils.setItem("access_token", access);
        await storageUtils.setItem("refresh_token", refresh);
        axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        processQueue(null, access);
        originalRequest.headers["Authorization"] = "Bearer " + access;
        return axios(originalRequest);
      } catch (err) {
        console.error('Token refresh error:', err.response?.data || err.message);
        processQueue(err, null);
        await storageUtils.removeItem("access_token");
        await storageUtils.removeItem("refresh_token");
        delete axios.defaults.headers.common["Authorization"];
        // return Promise.reject(err);
        throw err;
      } finally {
        isRefreshing = false;
      }
    }

    // return Promise.reject(error);
    throw error;
  }
);

export default axios;
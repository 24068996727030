import React from 'react';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import ResourceThumbnailPlaceholder from '../AddModals/ResourceThumbnailPlaceholder';
import { cleanAuthorName, convertResourceType } from '@linko/shared_utils';

const SlimResourceCard = ({ 
    thumbnail, type, title, author, year, onResourceClick, onAddToLibrary 
}) => {
      
  return (
    <div className='slim-resource-card'>
      <div className='slim-resource-card-content' onClick={onResourceClick}>
        {thumbnail ? 
          <img src={thumbnail} alt={title} style={{ width: '50px', height: '80px', objectFit: 'cover' }} />
        : <ResourceThumbnailPlaceholder type={type} width={"50px"} height={"80px"} />
        }
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', minHeight: '80px' }}>
          <div>
            <p style={{
              fontSize: '12px',
              color: '#999999'
            }}>
              {convertResourceType(type).toUpperCase()}
            </p>
            <p style={{
              fontSize: '16px',
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              lineHeight: '1.2em',
              maxHeight: '2.4em',
              marginBottom: '5px',
              color: '#333333'
            }}>
              {title}
            </p>
          </div>
          <div style={{ 
            fontSize: '12px', 
            color: '#999999',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            lineHeight: '1.2em',
            maxHeight: '2.4em'
          }}>
            {`${author ? cleanAuthorName(author) : 'Unknown Author'}${year ? `, ${year}` : ''}`}
          </div>
        </div>
      </div>
      {onAddToLibrary && (
        <Tippy 
          content={'Add to my library'}
          placement="left"
          arrow={true}
          theme='light-border'
        >
          <div 
            className='add-resource-icon' 
            onClick={(e) => {
              e.stopPropagation(); 
              onAddToLibrary();
            }}  
          >
            <MdOutlineLibraryAdd size={22} style={{ marginTop: '3px' }} />
          </div>
        </Tippy>
      )}
    </div>
  );
};

export default SlimResourceCard;

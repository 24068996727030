import React, {useState, useEffect, useCallback} from 'react';
import ResourceTypeIcon from '../ResourceCard/ResourceTypeIcon';
import Loader from '../Loader';
import { fetchResources } from '@linko/shared_utils';

const LinkResourceSuggested = ({setSelectedResource}) => {
    const [resources, setResources] = useState([]);
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(false);
    const [hasMoreResources, setHasMoreResources] = useState(true);
    
    const limit = 10;

    const fetchMyResources = useCallback(async () => {
        if (!isFetchingMoreResources || !hasMoreResources) return;
        
        try {
            const response = await fetchResources(
                limit,
                resourcesOffset,
                [],
                null,
                [],
                resources,
                setResources,
                setResourcesOffset, 
                setHasMoreResources, 
                setIsFetchingMoreResources,
                null,
            );
        } catch (error) {
            console.error('Failed to fetch resources:', error);
        } finally {
            setIsFetchingMoreResources(false);
        }
        }, [isFetchingMoreResources, hasMoreResources, resources, resourcesOffset]);

    useEffect(() => {
        setIsFetchingMoreResources(true);
    }, []);
    
    useEffect(() => {
        fetchMyResources();
    }, [isFetchingMoreResources]);

    const handleScroll = useCallback((event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop !== target.clientHeight) return;
        setIsFetchingMoreResources(true);
    }, []);

    useEffect(() => {
        const tabContainer = document.querySelector('.suggested-resources');
        tabContainer.addEventListener('scroll', handleScroll);
        return () => tabContainer.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);


    return (
        // JSX code for the component's UI goes here
        <div className='suggested-resources'>
            {resources.map((resource) =>
                <div
                    key={resource.resource.id}
                    className="search-results-resource"
                    onClick={() => setSelectedResource(resource.resource)}
                >
                    <ResourceTypeIcon type={resource.resource.type} size={16}/>
                    {resource.resource.title}
                </div>
            )}
            {isFetchingMoreResources && <Loader/>}
        </div>
    );
};

export default LinkResourceSuggested;
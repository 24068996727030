import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { useModal } from "../../Context/ModalContext";
// Context
import { AddNoteCardContext } from '../../Context/AppContext';
// Components
import EditNoteCard from "./EditNoteCard";
// Packages
import { postNote } from "@linko/shared_utils";
// Icons
import { PiNotePencilBold } from "react-icons/pi";

const AddNoteButton = ({
    resource,
    subjectId,
    noteHint,
    showTextArea,
    setShowTextArea,
    setNotes,
    setLibrary,
}) => {

    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [noteResource, setNoteResource] = useState(null);
    const { newNoteContent, setNewNoteContent } = useContext(AddNoteCardContext);
    const { hasUnsavedChanges, setUnsavedChanges } = useContext(NavigationPromptContext);
    const { requestConfirm, requestAlert } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (resource && resource.resource) {
            setNoteResource(resource.resource);
        }
    }, [resource]);

    useEffect(() => {
        if (newNoteContent !== '') {
            if (!showTextArea) {
                setShowTextArea(true);
            }
            
            const blockquoteContent = newNoteContent.split('\n').map(line => `> ${line}`).join('\n');
            
            setTimeout(() => {
                setNote(prevContent => {
                    return prevContent ? `${prevContent}\n\n${blockquoteContent}` : blockquoteContent;
                });
                setUnsavedChanges(true);
            }, 500);
            
            setNewNoteContent('');
        }
    }, [newNoteContent, showTextArea]);

    const handleAddButtonClick = () => {    
        setShowTextArea(true);
    };

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!note.trim()) {
            window.alert('Note cannot be empty');
            return;
        }
        
        setIsLoading(true);
        const newNote = {
            title: title,
            note: note,
            private: isPrivate,
            resource_id: noteResource ? noteResource.id : null,
            knowledge_id: subjectId ? subjectId : null,
        };
        
        const response = await postNote(newNote);
        if (response.status === 201) {
            setTitle('');
            setNote('');
            setIsPrivate(false);
            setShowTextArea(false);
            if (!location.pathname.includes('/r/')) {
                setNoteResource(null);
            }
            if (location.pathname.startsWith('/my_linko')) {
                const noteId = response.data.note.id;
                const encodedNoteId = btoa(noteId);
                navigate(`/n/${encodedNoteId}`);
            }
        } 
        setIsLoading(false);
        setUnsavedChanges(false);
        if (setNotes) {
            setNotes(prevNotes => [{...response.data.note, id: `N${response.data.note.id}`}, ...prevNotes]);
        }
        if (setLibrary) {
            setLibrary(prevLibrary => [{...response.data.note, id: `N${response.data.note.id}`}, ...prevLibrary]);
        }

    };

    // handleCancel
    const handleCancel = (event) => {
        event.preventDefault();
        if (hasUnsavedChanges) {
            requestConfirm(
                'You have unsaved changes. Are you sure you want to leave?',
                () => {
                    setShowTextArea(false);
                    setNote('');
                    setTitle('');
                    if (!location.pathname.includes('/r/')) {
                        setNoteResource(null);
                    }
                    setIsPrivate(false);
                    setUnsavedChanges(false);
                },
                null,
                'Leave'
            );
        } else {
            setShowTextArea(false);
            setNewNoteContent('');
            setTitle('');
            setNote('');
            if (!location.pathname.includes('/r/')) {
                setNoteResource(null);
            }
            setIsPrivate(false);
            setUnsavedChanges(false);
        }
    };

    return (
        <div className="add-note-card-button">
            {showTextArea ? (
                <EditNoteCard
                    isLoading={isLoading}
                    showTextArea={showTextArea}
                
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    updateSingleNote={null}

                    title={title}
                    setTitle={setTitle}
                    note={note}
                    setNote={setNote}
                    isPrivate={isPrivate}
                    setIsPrivate={setIsPrivate}
                    noteResource={noteResource}
                    setNoteResource={setNoteResource}
                />
            ) : (
                <button onClick={handleAddButtonClick} id="add-note-button" className='joyride-add-note'>
                    <div className="inside-add-note-button">
                        <PiNotePencilBold className="add-icon" />
                        <p className="show-on-hover" style={{position:'absolute', bottom:'10px', color:'#2e2e2e86', fontSize:'12px'}}>{noteHint}</p>
                    </div>
                </button>
            )}
        </div>
    );
};

export default AddNoteButton;

import React, { createContext, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal } from './ModalContext.jsx';

export const NavigationPromptContext = createContext();

export const NavigationPromptProvider = ({ children }) => {
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	const [changeOrigin, setChangeOrigin] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const { requestConfirm } = useModal();
	const isNavigatingRef = useRef(false);

	// Handle browser tab/window closing
	const handleBeforeUnload = useCallback((event) => {
		if (hasUnsavedChanges) {
			event.preventDefault();
			event.returnValue = '';
		}
	}, [hasUnsavedChanges]);

	// Handle browser back/forward buttons
	const handlePopState = useCallback(async (event) => {
		if (hasUnsavedChanges && !isNavigatingRef.current) {
			const confirmed = await new Promise(resolve => {
				requestConfirm(
					'You have unsaved changes. Are you sure you want to leave?',
					() => resolve(true),
					() => resolve(false)
				);
			});

			if (confirmed) {
				isNavigatingRef.current = true;
				setHasUnsavedChanges(false);
				setChangeOrigin(null);
				window.history.back();
			} else {
				// Push the current state back to prevent navigation
				window.history.pushState(null, '', location.pathname);
			}
		}
	}, [hasUnsavedChanges, location.pathname, requestConfirm]);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopState);
		};
	}, [handleBeforeUnload, handlePopState]);

	// Push a dummy state to handle the back navigation when there are unsaved changes
	useEffect(() => {
		if (hasUnsavedChanges) {
			window.history.pushState(null, '', location.pathname);
		}
	}, [hasUnsavedChanges, location.pathname]);

	// Reset isNavigatingRef after location changes to allow future navigations
	useEffect(() => {
		if (isNavigatingRef.current) {
			isNavigatingRef.current = false;
		}
	}, [location]);

	const safeNavigate = useCallback((to, options = {}) => {
		if (to === location.pathname) return;

		const performNavigation = () => {
			setHasUnsavedChanges(false);
			setChangeOrigin(null);
			navigate(to, options);
		};

		if (hasUnsavedChanges && location.pathname === changeOrigin) {
			requestConfirm(
				'You have unsaved changes. Are you sure you want to leave?', 
				performNavigation,
				() => {} // Optional: Handle cancellation if needed
			);
		} else {
			performNavigation();
		}
	}, [hasUnsavedChanges, changeOrigin, location.pathname, navigate, requestConfirm]);

	const setUnsavedChanges = useCallback((value) => {
		setHasUnsavedChanges(value);
		if (value) {
			setChangeOrigin(location.pathname);
		} else {
			setChangeOrigin(null);
		}
	}, [location.pathname]);

	return (
		<NavigationPromptContext.Provider value={{ hasUnsavedChanges, setUnsavedChanges, safeNavigate }}>
			{children}
		</NavigationPromptContext.Provider>
	);
};
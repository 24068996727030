import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import TabNotes from '../../Components/ContentTabs/TabNotes';
import TabResources from '../../Components/ContentTabs/TabResources';
import LibraryFilter from './LibraryFilter';
import LibrarySubjectFilter from './LibrarySubjectFilter';
import { handleScrollEvent, setupScrollListeners } from '../../Components/ContentTabs/handleScroll';
import { fetchNotes, fetchResources } from '@linko/shared_utils';
import Loader from '../../Components/Loader';
import { PiNotePencilBold } from "react-icons/pi";
import { RiBook2Line } from "react-icons/ri";
import { LibraryFilterContext } from '../../Context/AppContext';

const LibraryTabs = () => {

    const resourceAbortControllerRef = useRef(null); // Initialize the ref to null
    const noteAbortControllerRef = useRef(null);
    const isNoteInitialMount = useRef(true);
    const isResourceInitialMount = useRef(true);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.activeTab || 'resources');
    const [requestId, setRequestId] = useState(0);

    const { libraryFilters, setLibraryFilters } = useContext(LibraryFilterContext);

    const [notes, setNotes] = useState([]);
    const [notesOffset, setNotesOffset] = useState(0);
    const [isFetchingMoreNotes, setIsFetchingMoreNotes] = useState(true);
    const [hasMoreNotes, setHasMoreNotes] = useState(true);
    const [noteFilterSubject, setNoteFilterSubject] = useState(libraryFilters.notes.subjects);

    const [resources, setResources] = useState([]);
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(true);
    const [hasMoreResources, setHasMoreResources] = useState(true);

    const [filterType, setFilterType] = useState(libraryFilters.resources.types);
    const [filterFinished, setFilterFinished] = useState(libraryFilters.resources.finished);
    const [resourceFilterSubject, setResourceFilterSubject] = useState(libraryFilters.resources.subjects);

    const [isFetchingMoreContentFeed, setIsFetchingMoreContentFeed] = useState(false);
    const [hasMoreContentFeed, setHasMoreContentFeed] = useState(false);
    const [isFetchingMoreLibrary, setIsFetchingMoreLibrary] = useState(false);
    const [hasMoreLibrary, setHasMoreLibrary] = useState(false);

    const limit = 20;
    const latestResourceRequest = useRef(0);
    const latestNoteRequest = useRef(0);

    const [isLoadingResources, setIsLoadingResources] = useState(false);
    const [isLoadingNotes, setIsLoadingNotes] = useState(false);
    const prevResourceFiltersRef = useRef({ resourceFilterSubject: [], filterType: [], filterFinished: null });
    const prevNoteFiltersRef = useRef({ noteFilterSubject: [] });

    const applyResourceFilter = async () => {
        const currentFilters = { resourceFilterSubject, filterType, filterFinished };
        
        if (JSON.stringify(currentFilters) === JSON.stringify(prevResourceFiltersRef.current)) {
            return;
        }

        setIsLoadingResources(true);

        if (resourceAbortControllerRef.current) {
            resourceAbortControllerRef.current.abort();
        }

        const abortController = new AbortController();
        resourceAbortControllerRef.current = abortController;

        setResources([]);
        setResourcesOffset(0);
        setHasMoreResources(true);

        try {
            await fetchResources(
                limit,
                0,
                filterType,
                filterFinished,
                resourceFilterSubject,
                [],
                setResources,
                setResourcesOffset,
                setHasMoreResources,
                setIsFetchingMoreResources,
                resourceAbortControllerRef.current.signal
            );
        } catch (error) {
            console.error('Error fetching resources:', error);
        } finally {
            setIsLoadingResources(false);
        }

        prevResourceFiltersRef.current = currentFilters;
    };

    const applyNoteFilter = async () => {
        const currentFilters = { noteFilterSubject };
        
        if (JSON.stringify(currentFilters) === JSON.stringify(prevNoteFiltersRef.current)) {
            return;
        }

        setIsLoadingNotes(true);

        if (noteAbortControllerRef.current) {
            noteAbortControllerRef.current.abort();
        }

        const abortController = new AbortController();
        noteAbortControllerRef.current = abortController;

        setNotes([]);
        setNotesOffset(0);
        setHasMoreNotes(true);

        try {
            await fetchNotes(
                limit,
                0,
                null,
                null,
                [],
                setNotes,
                setNotesOffset,
                setHasMoreNotes,
                setIsFetchingMoreNotes,
                noteFilterSubject,
                noteAbortControllerRef.current.signal
            );
        } catch (error) {
            console.error('Error fetching notes:', error);
        } finally {
            setIsLoadingNotes(false);
        }

        prevNoteFiltersRef.current = currentFilters;
    };

    useEffect(() => {
        if (!isResourceInitialMount.current) {
            applyResourceFilter();
        } else {
            isResourceInitialMount.current = false;
        }
    }, [resourceFilterSubject, filterType, filterFinished]);

    useEffect(() => {
        if (!isNoteInitialMount.current) {
            applyNoteFilter();
        } else {
            isNoteInitialMount.current = false;
        }
    }, [noteFilterSubject]);

    useEffect(() => {
        if (isFetchingMoreNotes) {
            if (!noteAbortControllerRef.current) {
                noteAbortControllerRef.current = new AbortController();
            }
            fetchNotes(limit, notesOffset, null, null, notes, setNotes, setNotesOffset, setHasMoreNotes, setIsFetchingMoreNotes, noteFilterSubject, noteAbortControllerRef.current.signal);
        }
    }, [isFetchingMoreNotes]);

    useEffect(() => {
        if (isFetchingMoreResources) {
            if (!resourceAbortControllerRef.current) {
                resourceAbortControllerRef.current = new AbortController();
            }
            fetchResources(limit, resourcesOffset, filterType, filterFinished, resourceFilterSubject, resources, setResources, setResourcesOffset, setHasMoreResources, setIsFetchingMoreResources, resourceAbortControllerRef.current.signal);
        }
    }, [isFetchingMoreResources]);

    useEffect(() => {
        const cleanupScrollListeners = setupScrollListeners(
            handleScrollEvent,
            activeTab,
            setIsFetchingMoreNotes,
            setIsFetchingMoreResources,
            setIsFetchingMoreContentFeed,
            setIsFetchingMoreLibrary,
            hasMoreNotes,
            hasMoreResources,
            hasMoreContentFeed,
            hasMoreLibrary
        );

        return () => {
            cleanupScrollListeners();
        };
    }, [activeTab, hasMoreNotes, hasMoreResources, hasMoreContentFeed, hasMoreLibrary]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        let lastScrollTop = 0;
        const handleScroll = () => {
            const navbar = document.querySelector('.library-tabs-wrapper');
            const currentScroll = window.scrollY || document.documentElement.scrollTop;
            if (navbar) {
                if (currentScroll > lastScrollTop) {
                    navbar.style.top = '0px';
                } else {
                    navbar.style.top = '70px';
                }
                lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const updateNoteFilterSubject = (newSubjects) => {
        setNoteFilterSubject(newSubjects);
        setLibraryFilters(prevFilters => ({
            ...prevFilters,
            notes: {
                ...prevFilters.notes,
                subjects: newSubjects,
            },
        }));
    };

    const updateResourceFilters = (newSubjects, newTypes, newFinished) => {
        setResourceFilterSubject(newSubjects);
        setFilterType(newTypes);
        setFilterFinished(newFinished);
        setLibraryFilters(prevFilters => ({
            ...prevFilters,
            resources: {
                ...prevFilters.resources,
                subjects: newSubjects,
                types: newTypes,
                finished: newFinished,
            },
        }));
    };

    return (
        <div className='library-tabs'>
            <div className='library-tabs-wrapper' style={{ marginBottom: activeTab === 'notes' ? '10px' : '0px' }}>
                <div className='tabs' style={{columnGap: '15px'}}>
                    <button
                        onClick={() => handleTabChange('resources')}
                        className={'tab-button' + (activeTab === 'resources' ? ' active' : '')}
                    >
                        <RiBook2Line/>
                        &nbsp;
                        Resources
                    </button>
                    <button
                        onClick={() => handleTabChange('notes')}
                        className={'tab-button' + (activeTab === 'notes' ? ' active' : '')}
                    >
                        <PiNotePencilBold/>
                        &nbsp;
                        Notes
                    </button>
                    
                </div>
            </div>
            <div className='tab-content'>
                {activeTab === 'notes' &&
                    <>
                        <LibrarySubjectFilter
                            filterSubject={noteFilterSubject}
                            setFilterSubject={updateNoteFilterSubject}
                            activeTab={activeTab}
                        />
                        {isLoadingNotes ? (
                            <Loader />
                        ) : (
                            <TabNotes
                                notes={notes}
                                setNotes={setNotes}
                                isFetchingMoreNotes={isFetchingMoreNotes}
                                hasMoreNotes={hasMoreNotes}
                            />
                        )}
                    </>
                }
                {activeTab === 'resources' &&
                    <>
                        <div className='filter-button-wrapper' >
                            <LibraryFilter
                                filterType={filterType}
                                setFilterType={(newTypes) => updateResourceFilters(resourceFilterSubject, newTypes, filterFinished)}
                                filterFinished={filterFinished}
                                setFilterFinished={(newFinished) => updateResourceFilters(resourceFilterSubject, filterType, newFinished)}
                            />
                        </div>
                        <LibrarySubjectFilter
                            filterSubject={resourceFilterSubject}
                            setFilterSubject={(newSubjects) => updateResourceFilters(newSubjects, filterType, filterFinished)}
                            activeTab={activeTab}
                        />
                        {isLoadingResources ? (
                            <Loader />
                        ) : (
                            <TabResources
                                resources={resources}
                                setResources={setResources}
                                isFetchingMoreResources={isFetchingMoreResources}
                                hasMoreResources={hasMoreResources}
                            />
                        )}
                    </>
                }
            </div>
        </div>
    );
};

export default LibraryTabs;
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/AppContext';
import { FaChevronRight, FaExternalLinkAlt, FaCheck } from 'react-icons/fa';
import { useToast } from '../../Components/ToastNotification';
import { NameChangeModal, ResetPasswordModal } from './InputModals';
import { putUserData } from '@linko/shared_utils';

const SettingPage = () => {

    const { userInfo, setUserInfo, fetchUserInfo } = useContext(UserContext);
    const { addToast } = useToast();
    const [privateAccount, setPrivateAccount] = useState(userInfo?.private_account);
    const [setPrivateNotes, setSetPrivateNotes] = useState(false);
    const [isNameModalOpen, setIsNameModalOpen] = useState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

    const handleNameChange = () => {
        setIsNameModalOpen(true);
    };

    const handleUsernameChange = () => {
        // Implement username change logic
    };

    const handlePasswordReset = () => {
        setIsResetPasswordModalOpen(true);
    };

    const handlePrivacyChange = async (newValue) => {
        try {
            const response = await putUserData({ private_account: newValue });
            if (response.status === 200) {
                setPrivateAccount(newValue);
                // Update the userInfo in the context
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    private_account: newValue
                }));
                fetchUserInfo();
                addToast('Privacy settings updated successfully', { appearance: 'success' });
            } else {
                window.alert('Failed to update privacy settings');
            }
        } catch (error) {
            console.error('Failed to update privacy settings:', error);
            window.alert('Failed to update privacy settings');
        }
    };

    return (
        <div className="profile-page setting-page">
            <h1>Settings</h1>
            <section className="account-section">
                <h2>Account</h2>
                <div className="setting-item">
                <span>Email Address</span>
                <span style={{color:'#999'}}>{userInfo?.email}</span>
                </div>
                <div className="setting-item clickable" onClick={handleNameChange}>
                <span>Name</span>
                <span style={{color:'#999'}}>{userInfo?.first_name} {userInfo?.last_name} <FaChevronRight style={{paddingTop:'5px', color:'#999'}}/></span>
                </div>
                <div className="setting-item" onClick={handleUsernameChange}>
                <span>Username</span>
                <span style={{color:'#999'}}>
                    @{userInfo?.first_name}{userInfo?.last_name} 
                    {/* <FaChevronRight style={{paddingTop:'5px', color:'#999'}}/> */}
                </span>
                </div>
                <div className="setting-item">
                <span style={{whiteSpace:'nowrap'}}>Profile URL</span>
                <span className="link" onClick={() => window.open(userInfo?.profile_link, '_blank')} style={{color:'#999'}}>
                    {userInfo?.profile_link} 
                </span>
                </div>
            </section>
            <div className='rx-divider-vertical' style={{margin:'20px 0'}}></div>
            <section className="security-section">
                <h2>Security</h2>
                <div className="setting-item clickable" onClick={handlePasswordReset}>
                <span>Password</span>
                <span style={{color:'#999'}}>Reset Password <FaChevronRight style={{paddingTop:'5px', color:'#999'}}/></span>
                </div>
            </section>
            <div className='rx-divider-vertical' style={{margin:'20px 0'}}></div>
            <section className="privacy-section">
                <h2>Privacy</h2>
                <div className="setting-item checkbox-toggle">
                    <div>
                        <span>Set my profile as private</span>
                        <p className="description">Only your followers can view your public notes and resources.</p>
                    </div>
                    <label className="checkbox">
                        <input 
                            type="checkbox" 
                            checked={userInfo?.private_account || false} 
                            onChange={() => handlePrivacyChange(!userInfo?.private_account)}
                        />
                        <span className="checkbox-custom">
                            {userInfo?.private_account && <FaCheck className="check-icon" />}
                        </span>
                    </label>
                </div>
            </section>

            <NameChangeModal 
                isOpen={isNameModalOpen} 
                onClose={() => setIsNameModalOpen(false)} 
            />
            <ResetPasswordModal 
                isOpen={isResetPasswordModalOpen} 
                onClose={() => setIsResetPasswordModalOpen(false)} 
            />
        </div>
    );
};

export default SettingPage;

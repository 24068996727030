import React, { useState, useEffect, useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import NoteCard from '../NoteCard/NoteCard'; 
import { getSingleNote } from '@linko/shared_utils';
import { LuShuffle } from "react-icons/lu";
import { FaPlay, FaPause } from "react-icons/fa";
import Loader from '../Loader';
import axios from 'axios';
import { FiPlayCircle, FiPauseCircle } from "react-icons/fi";


const TabRevisit = ({
  revisitSubject, 
  revisitNote, 
  setRevisitNote, 
  isShufflingSubject, 
  setIsShufflingSubject
}) => {
  const { safeNavigate } = useContext(NavigationPromptContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentNoteIndex, setCurrentNoteIndex] = useState(0);
  const [audioElement, setAudioElement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    setAudioElement(new Audio());
  }, []);

  const handleShuffle = () => {
    setIsShufflingSubject(true);
  };

  const capitalize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const deleteNote = (noteId) => {
    setRevisitNote(revisitNote.filter(note => note.id !== noteId));
  };

  const updateNoteContent = (updatedNote) => {
    setRevisitNote(revisitNote.map(note => note.id === updatedNote.id ? updatedNote : note));
  };

  const updateSingleNote = (noteId) => {
    getSingleNote(noteId)
    .then((response) => {
        const updatedNote = response.data.note;
        setRevisitNote(revisitNote.map(note => note.id === noteId ? updatedNote : note));
    })
    .catch((error) => {
        console.error('Error fetching note:', error.response ? error.response.data : error.message);
    });
  }

  const playAllNotes = () => {
    if (isPlaying) {
      pauseSpeech();
    } else {
      speakNotes();
    }
  };

  const speakNotes = () => {
    setIsPlaying(true);
    if (isPaused) {
      audioElement.play();
      setIsPaused(false);
    } else {
      speakNote(currentNoteIndex);
    }
  };

  const speakNote = async (index) => {
    if (index >= revisitNote.length) {
      setIsPlaying(false);
      setCurrentNoteIndex(0);
      setIsPaused(false);
      return;
    }

    if (!isPaused) {
      setIsLoading(true);
      try {
        const response = await axios.post('/api/note/generate_speech/', {
          title: revisitNote[index].title,
          text: revisitNote[index].note
        });

        const audioBase64 = response.data.audio;
        const audioBlob = new Blob([Uint8Array.from(atob(audioBase64), c => c.charCodeAt(0))], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        
        audioElement.src = audioUrl;
        audioElement.onended = () => {
          URL.revokeObjectURL(audioUrl);
          setCurrentNoteIndex(index + 1);
          speakNote(index + 1);
        };
      } catch (error) {
        console.error("Error generating speech:", error);
        setIsPlaying(false);
        setIsPaused(false);
        return;
      } finally {
        setIsLoading(false);
      }
    }

    await audioElement.play();
    setIsPlaying(true);
    setIsPaused(false);
  };

  const pauseSpeech = () => {
    if (audioElement) {
      audioElement.pause();
    }
    setIsPlaying(false);
    setIsPaused(true);
  };

  const stopSpeech = () => {
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
    setIsPlaying(false);
    setIsPaused(false);
    setCurrentNoteIndex(0);
  };

  return (
    <div style={{width:'100%'}}>
      <div className="revisit-header">
        <div className="subject-title-container">
          <h2 className="subject-title" onClick={() => safeNavigate(`/s/${encodeURIComponent(capitalize(revisitSubject))}`)}>
            {isShufflingSubject ? '...' : capitalize(revisitSubject)}
          </h2>
        </div>
        {!isShufflingSubject &&
          <button className="play-all-button" onClick={playAllNotes} disabled={isLoading}>
            {isLoading ? <Loader size={20} /> : 
            isPlaying ? <FiPauseCircle size={18} color='#999'/> : <FiPlayCircle size={18} color='#999'/>}
          </button>
        }
        <button className="linko-button linko-button--tertiary shuffle-button" onClick={handleShuffle}>
          <LuShuffle size={18}/> Shuffle
        </button>
      </div>
      {isShufflingSubject ? 
        <div className='tab-content-loader'><Loader size={50}/></div>
        :
        <div className="cards-container" style={{marginBottom:'30px'}}>
          {revisitNote && revisitNote.map((note) => (
            <div className="revisit-note" key={note.id} style={{width:'100%'}}>
              <NoteCard 
                key={note.id}
                note={note}
                onDelete={deleteNote}
                onUpdate={updateNoteContent}
                shareView={false}
                subjectId={null}
                updateSingleNote={updateSingleNote}
              />
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default TabRevisit;
